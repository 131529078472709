import HttpService from "./htttp.service";

class SmsService {
  // authEndpoint = process.env.API_URL;

  getPaginated = async (payload, callback) => {
    const smsEndpoint = 'sms/paginated';
    let response = await HttpService.get(smsEndpoint, payload);
    callback(response);
    return;
  };
}

export default new SmsService();
