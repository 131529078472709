import HttpService from "./htttp.service";

class DevicesService {
  // authEndpoint = process.env.API_URL;

  getAllDevices = async (callback) => {
    const devicesEndpoint = 'devices/all';
    let response = await HttpService.get(devicesEndpoint);
    callback(response);
    return;
  };

}

export default new DevicesService();
