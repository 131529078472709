import HttpService from "./htttp.service";

class AuthService {
  // authEndpoint = process.env.API_URL;

  login = async (payload) => {
    const loginEndpoint = 'admins/login';
    return await HttpService.post(loginEndpoint, payload);
  };

  logout = async () => {
    const logoutEndpoint = 'admins/logout';
    return await HttpService.post(logoutEndpoint);
  };

  getProfile = async() => {
    const getProfile = 'admins/me';
    return await HttpService.get(getProfile);
  };

  updateProfile = async (newInfo) => {
    const updateProfile = "admins/me";
    return await HttpService.patch(updateProfile, newInfo);
  };
  
}

export default new AuthService();
