/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDBadge from "components/MDBadge";

export default function data(devices) {
  const DeviceName = ({ modelType, machineId }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDBox ml={2} lineHeight={1}>
        <MDTypography display="block" variant="button" fontWeight="medium">
          {modelType}
        </MDTypography>
        <MDTypography variant="caption">{machineId}</MDTypography>
      </MDBox>
    </MDBox>
  );

  const FullInfo = ({ operatorName, phoneNumber }) => (
    <MDBox lineHeight={1} textAlign="left">
      <MDTypography display="block" variant="caption" color="text" fontWeight="medium">
        { operatorName }
      </MDTypography>
      <MDTypography variant="caption">{ phoneNumber }</MDTypography>
    </MDBox>
  );

  return {
    column: [
      { Header: "Device name", accessor: "device_name", width: "45%", align: "left" },
      { Header: "Info", accessor: "info", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Created at", accessor: "created_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    row: devices.map((device,i)=>
      ({
        device_name: <DeviceName modelType={device.modelType} machineId={device.machineId} />,
        info: <FullInfo operatorName={device.operatorName} phoneNumber={device.phoneNumber} />,
        status: (
          <MDBox ml={-1}>
            <MDBadge badgeContent={device.status=="ONLINE"?"online":"offline"} color={device.status=="ONLINE"?"success":"error"} variant="gradient" size="sm" />
          </MDBox>
        ),
        created_at: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {new Date(device.createdAt).toDateString()}
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            Edit
          </MDTypography>
        ),
      }))
  };
}
