/* eslint-disable react/prop-types */
/* eslint-disable react/function-component-definition */
/**
=========================================================
* Material Dashboard 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDProgress from "components/MDProgress";

// Images
import LogoAsana from "assets/images/small-logos/logo-asana.svg";
import logoGithub from "assets/images/small-logos/github.svg";
import logoAtlassian from "assets/images/small-logos/logo-atlassian.svg";
import logoSlack from "assets/images/small-logos/logo-slack.svg";
import logoSpotify from "assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "assets/images/small-logos/logo-invision.svg";

export default function data(sms) {
  const Device = ({ devicePhoneNumber }) => (
    <MDBox display="flex" alignItems="center" lineHeight={1}>
      <MDTypography display="block" variant="button" fontWeight="medium" ml={1} lineHeight={1}>
        {devicePhoneNumber}
      </MDTypography>
    </MDBox>
  );

  return {
    column: [
      { Header: "Device", accessor: "dev", width: "30%", align: "left" },
      { Header: "Text", accessor: "message", align: "left" },
      { Header: "status", accessor: "status", align: "center" },
      { Header: "Receiver", accessor: "receiver", align: "center" },
      { Header: "Created at", accessor: "created_at", align: "center" },
      { Header: "action", accessor: "action", align: "center" },
    ],

    row: sms.map((s,i)=>
    ({
        dev: <Device devicePhoneNumber={s.devicePhoneNumber} />,
        message: (
          <MDTypography component="a" href="#" variant="button" color="text" fontWeight="medium">
            {s.message}
          </MDTypography>
        ),
        status: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {s.status}
          </MDTypography>
        ),
        receiver: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {s.receiver}
          </MDTypography>
        ),
        created_at: (
          <MDTypography component="a" href="#" variant="caption" color="text" fontWeight="medium">
            {new Date(s.createdAt).toDateString()}
          </MDTypography>
        ),
        action: (
          <MDTypography component="a" href="#" color="text">
            <Icon>Edit</Icon>
          </MDTypography>
        ),
      }))
  };
}
